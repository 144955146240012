<template>
  <div>
    <v-autocomplete
      ref="selectedProduct"
      v-model="selectedProduct"
      append-outer-icon="mdi-restore"
      label="Produkte"
      :disabled="disabled || productsLoading"
      :items="availableProducts"
      :search-input="productSearchInput"
      item-text="name"
      item-value="id"
      no-data-text="Produkte wurden noch nicht geladen."
      :rules="[
        (selection) =>
          !isRequired ||
          selection != null ||
          'Es muss ein Produkt ausgewählt werden!'
      ]"
      dense
      persistent-placeholder
      persistent-hint
      return-object
      @click:append-outer="resetInput()"
    />
  </div>
</template>

<script>
import { HTTP } from '@/main/httpClient.js';
import NotificationObject from '@/main/NotificationObject.js';
import lodash from 'lodash';
import ErrorMessageBuilder from '@/util/ErrorMessageBuilder.js';

export default {
  name: 'ProductSelection',
  props: {
    // If true an error message will be displayed in case there was no selection made.
    isRequired: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      // Disables the selections as long as the parent component is not active and therefore
      // does not allow any userinput
      type: Boolean,
      required: true
    },
    productType: {
      // Can be either 'ONT' or 'CPE'
      type: String,
      required: true
    },
    presentProductData: {
      // Can be either an object with the fields of an existing product or null or undefined.
      type: Object,
      required: false,
      default: undefined
    },
    previouslySelectedProduct: {
      // Can be either an object with the fields of the currently selected product.
      //  Must be initialized as undefined. If initalized null this component will assume that it was set to null intentianally.
      type: Object,
      required: false,
      default: undefined
    }
  },
  data: function () {
    return {
      productsLoading: false,
      presentProduct: null,
      availableProducts: [],
      selectedProduct: undefined,
      selectedProductInputValid: true,
      productSearchInput: ''
    };
  },
  computed: {
    inputIsValid: function () {
      return this.selectedProduct != null;
    }
  },
  watch: {
    isRequired: {
      handler(newValue, _) {
        this.$refs.selectedProduct.validate(true);
      }
    },
    presentProductData: {
      handler(newProductData, oldValue) {
        if (
          newProductData &&
          lodash.isEqual(newProductData, oldValue) === false
        ) {
          this.presentProduct = lodash.cloneDeep(newProductData);
          if (this.previouslySelectedProduct === undefined) {
            this.selectedProduct = this.presentProduct;
          }
        }
      },
      immediate: true
    },
    previouslySelectedProduct: {
      handler(newProductData, oldValue) {
        if (
          newProductData &&
          lodash.isEqual(newProductData, oldValue) === false
        ) {
          this.selectedProduct = lodash.cloneDeep(newProductData);
        } else if (newProductData === null) {
          this.selectedProduct = null;
        }
      },
      immediate: true
    },
    // emit watchers
    selectedProduct: {
      handler(newProduct, oldProduct) {
        if (
          newProduct !== undefined &&
          lodash.isEqual(newProduct, oldProduct) === false
        ) {
          this.addPresentProductToAvailableProductsIfRequired();
          this.$emit('selectedProductChanged', newProduct);
        }
      },
      immediate: true
    },
    inputIsValid: {
      immediate: true,
      handler(newValue, oldValue) {
        this.$emit('inputIsValidChanged', newValue);
      }
    }
  },
  mounted: function () {
    this.$refs.selectedProduct.validate(true);
    this.fetchAvailableProducts(this.productType);
  },
  methods: {
    showMessage: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },

    buildRequiredDeviceTypes(deviceType) {
      if (deviceType === 'CPE') {
        return ['RTR', 'RTR_ACS', 'ONT_RTR_ACS'];
      } else if (deviceType === 'ONT') {
        return ['ONT'];
      } else {
        return ['STB'];
      }
    },
    fetchAvailableProducts(productType) {
      const requiredDeviceTypes = this.buildRequiredDeviceTypes(productType);
      this.productsLoading = true;
      HTTP.get('/products/cpeDevices', {
        params: {
          deviceTypes: requiredDeviceTypes
        },
        paramsSerializer: function paramsSerializer(params) {
          return Object.entries(Object.assign({}, params))
            .map(([key, value]) => {
              if (value.length === 0) {
                return `${key}=${value}`;
              } else {
                return value
                  .map((listValue) => `${key}=${listValue}`)
                  .join('&');
              }
            })
            .join('&');
        }
      })
        .then((response) => {
          this.availableProducts = response.data;
          this.addPresentProductToAvailableProductsIfRequired();
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Die verfügbaren ONT-Modelle konnten nicht geladen werden!'
            )
          )
        )
        .finally(() => {
          this.productsLoading = false;
        });
    },
    addPresentProductToAvailableProductsIfRequired() {
      // In case the loaded models do not contain the present ONT model add it to the list of the available models.
      // This case is very unlikely
      if (
        this.presentProduct != null &&
        this.availableProducts !== [] &&
        !this.availableProducts.includes(this.presentProduct)
      ) {
        this.availableProducts.push(this.presentProduct);
      }
    },
    resetInput() {
      this.productSearchInput = '';
      if (this.presentProduct !== null && this.presentProduct !== undefined) {
        this.selectedProduct = this.presentProduct;
      } else {
        this.selectedProduct = null;
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
