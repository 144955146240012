<template>
  <div>
    <DataDisplay :label="label">
      <div
        v-if="
          addressDto &&
            (addressDto.company || (addressDto.firstName && addressDto.lastName))
        "
      >
        <div v-if="addressDto.company">
          <div v-if="addressDto.firstName && addressDto.lastName">
            {{ addressDto.firstName }} {{ addressDto.lastName }}
          </div>
          <div>
            {{ addressDto.company }}
          </div>
        </div>
        <div v-else-if="addressDto.firstName && addressDto.lastName">
          {{ formattSalutationEnum(addressDto) }}
          {{ addressDto.firstName }}
          {{ addressDto.lastName }}
        </div>
      </div>
    </DataDisplay>
  </div>
</template>

<script>
import DataDisplay from '@/components/elements/DataDisplay.vue';
import AddressUtil from '@/util/AddressUtil.js';
export default {
  name: 'NameDisplay',
  components: { DataDisplay },
  props: {
    addressDto: {
      type: Object,
      required: false,
      default: null
    }
  },
  computed: {
    label() {
      if (this.addressDto && this.addressDto.company) {
        if (this.addressDto.firstName && this.addressDto.lastName) {
          return 'Ansprechpartner, Firma';
        } else {
          return 'Firma';
        }
      } else {
        return 'Anrede, Vor-/Nachname';
      }
    }
  },
  methods: {
    formattSalutationEnum: function (addressDto) {
      return AddressUtil.formattSalutationEnum(addressDto);
    }
  }
};
</script>

<style></style>
