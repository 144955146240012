var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DataDisplay',{attrs:{"label":_vm.label}},[(
        _vm.addressDto &&
          (_vm.addressDto.zipCode ||
            _vm.addressDto.city ||
            _vm.addressDto.houseNumber ||
            _vm.addressDto.houseNumberSupplement)
      )?_c('div',[(_vm.addressDto.street)?_c('div',[_c('span',[_vm._v(_vm._s(_vm.addressDto.street)+"  ")]),(_vm.addressDto.houseNumberSupplement)?_c('span',[_vm._v(" "+_vm._s(_vm.addressDto.houseNumber)+_vm._s(_vm.addressDto.houseNumberSupplement)+" ")]):(
            !_vm.addressDto.alternativeLocationDescriptionIsSelected &&
              _vm.addressDto.houseNumber
          )?_c('span',[_vm._v(" "+_vm._s(_vm.addressDto.houseNumber)+" ")]):(
            _vm.addressDto.alternativeLocationDescriptionIsSelected &&
              _vm.addressDto.alternativeLocationDescription
          )?_c('span',[_vm._v(" "+_vm._s(_vm.addressDto.alternativeLocationDescription)+" ")]):_vm._e()]):_vm._e(),_c('div',[_vm._v(_vm._s(_vm.addressDto.zipCode)+" "+_vm._s(_vm.addressDto.city))])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }