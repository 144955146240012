<template>
  <div>
    <div class="input-row">
      <DataDisplay :dataIsLoading="dataIsLoading" label="MSAN (IP)">
        <div v-if="data && data.msan_ip">
          {{ data.msan_domain_name + ' (' + data.msan_ip + ')' }}
        </div>
      </DataDisplay>
      <DataDisplay :dataIsLoading="dataIsLoading" label="Port Beschreibung">
        <div v-if="data && data.port_description">
          {{ data.port_description }}
        </div>
      </DataDisplay>

      <DataDisplay :dataIsLoading="dataIsLoading" label="MSAN Port">
        <div v-if="data && data.msan_port">
          {{ data.msan_port }}
        </div>
      </DataDisplay>
      <DataDisplay :dataIsLoading="dataIsLoading" label="Port Typ">
        <div v-if="data && data.port_type">
          {{ data.port_type }}
        </div>
      </DataDisplay>
      <DataDisplay :dataIsLoading="dataIsLoading" label="MSAN Typ">
        <div v-if="data && data.msan_type">
          {{ data.msan_type }}
        </div>
      </DataDisplay>
      <DataDisplay :dataIsLoading="dataIsLoading" label="Prov ID">
        <div v-if="data && data.port_vlan && data.port_vlan.pvid">
          {{ data.port_vlan.pvid }}
        </div>
      </DataDisplay>
      <DataDisplay :dataIsLoading="dataIsLoading" label="MSAN Anbieter">
        <div v-if="data && data.msan_vendor">
          {{ data.msan_vendor }}
        </div>
      </DataDisplay>
      <DataDisplay :dataIsLoading="dataIsLoading" label="Port Vlan">
        <div v-if="data">
          <div v-if="data.port_vlan">
            <div
              v-for="item in data.port_vlan.vlans"
              v-show="data.port_vlan"
              :key="item.cvlan"
              style="display: flex; flex-direction: column"
            >
              cvlan: {{ item ? item.cvlan : '-' }}, svlan:
              {{ item ? item.svlan : '-' }}
            </div>
          </div>
          <div v-else>
            -
          </div>
        </div>
      </DataDisplay>
      <DataDisplay :dataIsLoading="dataIsLoading" label="ONT Seriennummer">
        <div v-if="data && data.ont_serialnumber">
          {{ data.ont_serialnumber }}
        </div>
      </DataDisplay>
      <DataDisplay :dataIsLoading="dataIsLoading" label="Port Upstream">
        <div v-if="data && data.port_upstream">
          {{ data.port_upstream }}
        </div>
      </DataDisplay>
      <div></div>
      <DataDisplay :dataIsLoading="dataIsLoading" label="Port Downstream">
        <div v-if="data && data.port_downstream">
          {{ data.port_downstream }}
        </div>
      </DataDisplay>
    </div>
  </div>
</template>

<script>
import DisplayContainer from '@/components/elements/DisplayContainer.vue';
import DataDisplay from '@/components/elements/DataDisplay.vue';

export default {
  components: {
    DisplayContainer,
    DataDisplay
  },

  props: {
    dataIsLoading: {
      type: Boolean,
      required: false,
      defeault: false
    },
    data: {
      type: Object,
      required: false,
      default: null
    }
  }
};
</script>

<style scoped></style>
