<template>
  <div>
    <DataDisplay :label="label">
      <div class="input-row">
        <div class="display-body">
          <div>Kontoinhaber:&nbsp;</div>
          <div>
            {{
              sepaMandateDto && sepaMandateDto.owner
                ? sepaMandateDto.owner
                : '-'
            }}
          </div>
          <div>Kreditinstitut:&nbsp;</div>
          <div>
            {{
              sepaMandateDto && sepaMandateDto.bank ? sepaMandateDto.bank : '-'
            }}
          </div>
        </div>
        <div class="display-body">
          <div>IBAN:&nbsp;</div>
          <div>
            {{
              sepaMandateDto && sepaMandateDto.iban ? sepaMandateDto.iban : '-'
            }}
          </div>
          <div>BIC:&nbsp;</div>
          <div>
            {{
              sepaMandateDto && sepaMandateDto.bic ? sepaMandateDto.bic : '-'
            }}
          </div>
        </div>
      </div>
    </DataDisplay>
  </div>
</template>

<script>
import DataDisplay from '@/components/elements/DataDisplay.vue';

export default {
  name: 'ContactInformationDisplay',
  components: { DataDisplay },
  props: {
    sepaMandateDto: {
      type: Object,
      required: false,
      default: null
    },
    label: {
      type: String,
      required: false,
      default: 'Zahlungsinformationen'
    }
  }
};
</script>

<style scoped lang="scss">
.display-body {
  display: grid;
  grid-template-columns: min-content auto;
}
</style>
