<template>
  <div class="input-row">
    <DataDisplay :label="`Produkt für ${productType}-Gerät`">
      <div v-if="productName">
        {{ productName }}
      </div>
    </DataDisplay>
    <DataDisplay :label="deviceLabel">
      <div v-if="deviceName">
        {{ deviceName }}
      </div>
    </DataDisplay>
  </div>
</template>

<script>
import DataDisplay from '@/components/elements/DataDisplay.vue';
export default {
  name: 'HardwareDeviceProductWithDevice',
  components: { DataDisplay },
  props: {
    // Product type of the hardware device, i.e. ONT or CPE
    productType: {
      type: String, // ENUM => ONT or CPE
      required: true
    },
    // The product that is displayed by this component
    productWithDevice: {
      type: Object, // See ProductWithCpeDeviceDto.java
      required: false,
      default: null
    }
  },
  data: () => ({ deviceLabel: 'Gerät', productName: null, deviceName: null }),
  watch: {
    productWithDevice: {
      handler: function (productWithDevice, _) {
        if (productWithDevice === null || productWithDevice === undefined) {
          this.deviceLabel = this.productType;
          this.productName = null;
          this.deviceName = null;
        } else {
          this.productName = productWithDevice.productName;
          if (
            productWithDevice.product !== null &&
            productWithDevice.product !== undefined
          ) {
            this.productName = productWithDevice.product.name;
          } else {
            this.productName = null;
          }
          if (
            productWithDevice.device !== null &&
            productWithDevice.device !== undefined
          ) {
            this.deviceLabel = `${this.productType} (${productWithDevice.device.model})`;
            this.deviceName = productWithDevice.device.serialNumber;
          } else {
            this.deviceLabel = this.productType;
            this.deviceName = null;
          }
        }
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style></style>
