<template>
  <div>
    <v-autocomplete
      ref="selectMsanChassis"
      v-model="selectedMsanChassis"
      class="margin-top-1"
      :disabled="
        (availableMsanChassis.length ? false : true) ||
          disabled ||
          fetchingAutoSelection
      "
      :items="availableMsanChassis"
      :loading="msanChassisLoading"
      append-outer-icon="mdi-restore"
      :rules="[
        (selection) =>
          selection != null || 'Es muss ein MSAN-Chassis ausgewählt werden!'
      ]"
      dense
      item-text="searchLabel"
      item-value="serviceDataId"
      label="MSAN"
      persistent-hint
      persistent-placeholder
      return-object
      @change="msanSelectionChanged($event.serviceDataId)"
      @click:append-outer="resetMsanSelection()"
    />
    <v-autocomplete
      ref="selectPonPort"
      v-model="selectedPonPort"
      class="margin-top-1"
      append-outer-icon="mdi-restore"
      :disabled="
        (availablePonPorts.length ? false : true) ||
          disabled ||
          fetchingAutoSelection
      "
      :items="availablePonPorts"
      :loading="ponPortsLoading"
      :rules="[
        (selection) =>
          selection != null || 'Es muss ein Pon-Port ausgewählt werden!'
      ]"
      dense
      item-text="searchLabel"
      item-value="serviceDataId"
      label="Pon-Port"
      persistent-hint
      persistent-placeholder
      return-object
      @change="ponPortSelectionChanged($event.serviceDataId)"
      @click:append-outer="resetPonPortSelection()"
    />
    <v-autocomplete
      ref="selectUniPort"
      v-model="selectedUniPort"
      class="margin-top-1"
      :disabled="
        (availableUniPorts.length ? false : true) ||
          disabled ||
          fetchingAutoSelection
      "
      :items="availableUniPorts"
      append-outer-icon="mdi-restore"
      :loading="uniPortsLoading"
      :rules="[
        (selection) =>
          selection != null || 'Es muss ein Uni-Port ausgewählt werden!'
      ]"
      dense
      item-text="searchLabel"
      item-value="serviceDataId"
      label="Uni-Port"
      persistent-hint
      persistent-placeholder
      return-object
      @click:append-outer="resetUniPortSelection()"
    />
  </div>
</template>

<script>
import { HTTP } from '@/main/httpClient.js';
import NotificationObject from '@/main/NotificationObject.js';
import ErrorMessageBuilder from '@/util/ErrorMessageBuilder.js';

export default {
  name: 'UniPortSelection',
  props: {
    disabled: {
      // Disables the selections as long as the parent component is not active and therefore
      // does not allow any userinput
      type: Boolean,
      required: true
    },
    presentUniPortid: {
      // Can be either the uni port id of the presend uniPort or null or undefined.
      type: Number,
      required: false,
      default: undefined
    },
    previouslySelectedUniPortId: {
      // Can be either an object with the fields of the currently selected uni port id.
      //  Must be initialized as undefined. If initalized null this component will assume that it was set to null intentianally.
      type: Number,
      required: false,
      default: undefined
    }
  },
  data: () => ({
    msanChassisLoading: false,
    availableMsanChassis: [],
    existingMsanChassis: null,
    selectedMsanChassis: null,
    ponPortsLoading: false,
    availablePonPorts: [],
    existingPonPort: null,
    selectedPonPort: null,
    uniPortsLoading: false,
    availableUniPorts: [],
    existingUniPort: null,
    selectedUniPort: null,
    fetchingAutoSelection: false
  }),
  computed: {
    inputIsValid: function () {
      return (
        this.selectedMsanChassis != null &&
        this.selectedPonPort != null &&
        this.selectedUniPort != null
      );
    }
  },
  watch: {
    presentUniPortid: {
      handler(newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
          this.retrieveSearchInformationForPresentData(newValue);
        }
      },
      immediate: true
    },
    previouslySelectedUniPortId: {
      handler(newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
          this.retrieveSearchInformationForPresentData(newValue);
        } else if (newValue === null) {
          this.existingMsanChassis = null;
          this.existingPonPort = null;
          this.existingUniPort = null;
          this.resetPonPorts();
          this.resetUniPorts();
        }
      },
      immediate: true
    },
    // emit watchers
    selectedUniPort: {
      immediate: true,
      handler(newUniPort, _) {
        this.$emit('selectedUniPortChanged', newUniPort);
      }
    },
    inputIsValid: {
      immediate: true,
      handler(newValue, _) {
        this.$emit('inputIsValidChanged', newValue);
      }
    }
  },
  mounted: function () {
    this.$refs.selectMsanChassis.validate(true);
    this.$refs.selectPonPort.validate(true);
    this.$refs.selectUniPort.validate(true);
    this.fetchChassis();
  },
  methods: {
    showMessage: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },
    msanSelectionChanged(msanId) {
      this.resetPonPorts();
      this.resetUniPorts();
      this.fetchPonports(msanId);
    },
    ponPortSelectionChanged(ponPortId) {
      this.resetUniPorts();
      this.fetchUniports(ponPortId);
    },
    fetchChassis() {
      this.msanChassisLoading = true;
      HTTP.get('/components/msanChassis/availableElements')
        .then((res) => {
          this.availableMsanChassis = res.data;
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Die verfügbaren MSAN-Chassis konnten nicht geladen werden!'
            )
          )
        )
        .finally(() => (this.msanChassisLoading = false));
    },
    fetchPonports(msanId) {
      this.ponPortsLoading = true;
      HTTP.get(`/components/ponPorts/availableElements/${msanId}`)
        .then((res) => {
          this.availablePonPorts = res.data;
        })
        .catch((e) => e)
        .finally(() => (this.ponPortsLoading = false));
    },
    fetchUniports(ponPortId) {
      this.uniPortsLoading = true;
      HTTP.get(`/components/uniPorts/availableElements/${ponPortId}`)
        .then((res) => {
          this.availableUniPorts = res.data;
          this.addExistingUniPortToAvailableUniPortsIfRequired();
        })
        .catch((e) => e)
        .finally(() => (this.uniPortsLoading = false));
    },
    retrieveSearchInformationForPresentData(currentUniPortId) {
      this.fetchingAutoSelection = true;
      HTTP.get('components/uniPorts/searchInformation', {
        params: { uniPortId: currentUniPortId }
      })
        .then((response) => {
          this.existingMsanChassis = response.data.msanChassis;
          this.existingPonPort = response.data.ponPort;
          this.existingUniPort = response.data.uniPort;
          if (
            !this.previouslySelectedUniPortId &&
            !this.selectedMsanChassis &&
            !this.selectedPonPort &&
            !this.selectedUniPort
          ) {
            // Only perform a reset if no other input has been made already
            this.resetMsanSelection();
            this.resetPonPortSelection();
            this.resetUniPortSelection();
          }
        })
        .finally(() => (this.fetchingAutoSelection = false));
    },
    retrieveSearchInformationForPreviouslySelectedData(currentUniPortId) {
      this.fetchingAutoSelection = true;
      HTTP.get('components/uniPorts/searchInformation', {
        params: { uniPortId: currentUniPortId }
      })
        .then((response) => {
          this.selectedMsanChassis = response.data.msanChassis;
          this.selectedPonPort = response.data.ponPort;
          this.selectedUniPort = response.data.uniPort;
          this.fetchPonports(this.selectedMsanChassis.serviceDataId);
          this.fetchUniports(this.selectedUniPort.serviceDataId);
        })
        .finally(() => (this.fetchingAutoSelection = false));
    },
    resetMsanSelection() {
      if (this.existingMsanChassis) {
        this.selectedMsanChassis = this.existingMsanChassis;
        this.fetchPonports(this.selectedMsanChassis.serviceDataId);
      } else {
        this.selectedMsanChassis = null;
      }
    },
    resetPonPortSelection() {
      if (
        this.existingPonPort &&
        this.existingMsanChassis &&
        this.selectedMsanChassis === this.existingMsanChassis
      ) {
        this.selectedPonPort = this.existingPonPort;
        this.fetchUniports(this.selectedPonPort.serviceDataId);
      } else {
        this.selectedPonPort = null;
      }
    },
    resetUniPortSelection() {
      if (
        this.existingUniPort &&
        this.existingPonPort &&
        this.existingPonPort === this.selectedPonPort
      ) {
        this.selectedUniPort = this.existingUniPort;
      } else {
        this.selectedUniPort = null;
      }
    },
    resetPonPorts() {
      this.resetPonPortSelection();
      this.availablePonPorts = [];
    },
    resetUniPorts() {
      this.resetUniPortSelection();
      this.availableUniPorts = [];
    },
    /**
     * This is only required for the uniport because only uni ports are connected to a contract and therefore not in the list of available elements
     */
    addExistingUniPortToAvailableUniPortsIfRequired() {
      if (
        this.existingUniPort != null &&
        !this.availableUniPorts.includes(this.existingUniPort)
      ) {
        // Add present device
        this.availableUniPorts.push(this.existingUniPort);
      }
    }
  }
};
</script>

<style scoped></style>
