<template>
  <div>
    <DataDisplay label="Registrierungsstatus">
      <div
        v-if="
          provisioningInformation &&
            (provisioningInformation.msanIsAlreadyProvisioned ||
              provisioningInformation.msanStatus === 'up')
        "
        class="align-elements margin-bottom-1"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on">
              mdi-web-check
            </v-icon>
          </template>
          <span>
            MSAN-Status:
            {{ provisioningInformation.msanStatus }}
          </span>
        </v-tooltip>
        &nbsp; Registriert
      </div>
      <div v-else class="align-elements margin-bottom-1">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on">
              mdi-web-off
            </v-icon>
          </template>
          <span> MSAN wurde noch nicht provisioniert </span>
          <span v-if="provisioningInformation">
            MSAN-Status:
            {{ provisioningInformation.msanStatus }}
          </span>
        </v-tooltip>
        &nbsp; Nicht registriert
      </div>
    </DataDisplay>

    <DataDisplay label="Signallevel Empfang">
      <div
        v-if="
          provisioningInformation &&
            provisioningInformation.portReceiveSignalLevelDbm
        "
        class="align-elements"
      >
        {{ provisioningInformation.portReceiveSignalLevelDbm }} dBm
      </div>
    </DataDisplay>

    <DataDisplay label="Entfernung zum OLT">
      <div
        v-if="
          provisioningInformation && provisioningInformation.portDistanceToOltKm
        "
        class="align-elements"
      >
        {{ provisioningInformation.portDistanceToOltKm }} km
      </div>
    </DataDisplay>
  </div>
</template>

<script>
import DataDisplay from '@/components/elements/DataDisplay.vue';
export default {
  name: 'MsanProvisioningInformation',
  components: {
    DataDisplay
  },
  props: {
    provisioningInformation: {
      type: Object,
      required: false,
      default: null
    }
  }
};
</script>

<style scoped></style>
