<template>
  <div class="hardwareDeviceSelectionGrid">
    <v-autocomplete
      ref="selectedDevice"
      v-model="selectedDevice"
      label="Seriennummer"
      append-outer-icon="mdi-restore"
      :disabled="disabled"
      :items="availableDevices"
      :search-input="deviceSearchInput"
      item-text="serialNumber"
      item-value="deviceId"
      no-data-text="Geräte wurden noch nicht geladen."
      :hint="
        !deviceType && !disabled
          ? 'Für die Suche muss ein Produkt ausgewählt werden.'
          : null
      "
      :rules="[
        (selection) =>
          !isRequired ||
          selection != null ||
          'Es muss eine Seriennummer ausgewählt werden!'
      ]"
      dense
      persistent-hint
      persistent-placeholder
      return-object
      @update:search-input="
        {
          deviceSearchInput = $event != null ? $event : '';
          if (
            !selectedDevice ||
            deviceSearchInput !== selectedDevice.serialNumber
          ) {
            fetchSerialnumbers(deviceType, deviceSearchInput);
          }
        }
      "
      @click:append-outer="resetInput()"
    >
    </v-autocomplete>
  </div>
</template>

<script>
import { HTTP } from '@/main/httpClient.js';
import NotificationObject from '@/main/NotificationObject.js';
import lodash from 'lodash';
import ErrorMessageBuilder from '@/util/ErrorMessageBuilder.js';

export default {
  name: 'HardwareDeviceSelection',
  props: {
    disabled: {
      // Disables the selections as long as the parent component is not active and therefore
      // does not allow any userinput
      type: Boolean,
      required: false,
      default: false
    },
    // If true an error message will be displayed in case there was no selection made.
    isRequired: {
      type: Boolean,
      required: false,
      default: false
    },
    deviceType: {
      // Can be either 'RTR', 'RTR_ACS', 'ONT', 'ONT_RTR_ACS' or 'STB'
      type: String,
      required: false,
      default: 'ONT'
    },
    presentDeviceData: {
      // The device that is already present. If this is not null or undefined the device selection will
      // be reset to this value. If there is no previously selected device this will be the default value
      // Can be either an object with the fields of an existing device or null
      type: Object,
      required: false,
      default: null
    },
    previouslySelectedDevice: {
      // Previously selected device. When loaded and not undefined this is the default value for the selection
      // Can be either an object with the fields of an existing device or null
      type: Object,
      required: false,
      default: undefined
    }
  },
  data: function () {
    return {
      devicesLoading: false,
      presentDevice: null,
      availableDevices: [],
      selectedDevice: undefined,
      deviceSearchInput: ''
    };
  },
  computed: {
    inputIsValid: function () {
      return this.selectedDevice != null;
    }
  },
  watch: {
    isRequired: {
      handler(newValue, _) {
        this.$refs.selectedDevice.validate(true);
      }
    },
    presentDeviceData: {
      handler(newDeviceData, oldValue) {
        if (
          newDeviceData &&
          lodash.isEqual(newDeviceData, oldValue) === false
        ) {
          const presentDevice = lodash.cloneDeep(newDeviceData);
          this.presentDevice = {
            serviceDataId: presentDevice.serviceDataId,
            serialNumber: presentDevice.serialNumber,
            model: presentDevice.model,
            cpeDeviceType: presentDevice.cpeDeviceType
          };
          if (this.previouslySelectedDevice === undefined) {
            this.selectedDevice = this.presentDevice;
            this.deviceSearchInput = '';
          }
        }
      },
      immediate: true
    },
    previouslySelectedDevice: {
      handler(newDeviceData, oldValue) {
        if (
          newDeviceData != null &&
          lodash.isEqual(newDeviceData, oldValue) === false
        ) {
          const previouslySelectedDevice = lodash.cloneDeep(newDeviceData);
          this.selectedDevice = {
            serviceDataId: previouslySelectedDevice.serviceDataId,
            serialNumber: previouslySelectedDevice.serialNumber,
            model: previouslySelectedDevice.model,
            cpeDeviceType: previouslySelectedDevice.cpeDeviceType
          };
          this.deviceSearchInput = '';
        } else if (newDeviceData === null) {
          this.selectedDevice = null;
        }
      },
      immediate: true
    },
    selectedDevice: {
      immediate: true,
      handler(newDevice, _) {
        if (newDevice !== undefined) {
          this.addPresentDeviceToAvailableDevicesIfRequired();
          this.$emit('selectedDeviceChanged', newDevice);
        }
      }
    },
    inputIsValid: {
      immediate: true,
      handler(newValue, oldValue) {
        this.$emit('inputIsValidChanged', newValue);
      }
    },
    deviceType: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue) {
          this.fetchSerialnumbers(newValue, this.deviceSearchInput);
        } else if (
          (newValue === null && !this.selectedDevice) ||
          (this.selectedDevice !== null &&
            this.selectedDevice !== undefined &&
            newValue !== undefined &&
            newValue !== null &&
            newValue !== this.selectedDevice.cpeDeviceType)
        ) {
          this.resetInput();
        }
      }
    }
  },
  mounted: function () {
    this.$refs.selectedDevice.validate(true);
    this.fetchSerialnumbers(this.deviceType, this.deviceSearchInput);
  },
  methods: {
    showMessage: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },
    fetchSerialnumbers(deviceType, deviceSearchInput) {
      if (deviceType) {
        this.devicesLoading = true;
        HTTP.get('/components/cpeDevices/bySerialNumbersInfix', {
          params: {
            deviceType: deviceType,
            serialNumberInfix: deviceSearchInput
          }
        })
          .then((response) => {
            this.availableDevices = response.data;
            this.addPresentDeviceToAvailableDevicesIfRequired();
          })
          .catch((err) =>
            this.showMessage(
              'error',
              ErrorMessageBuilder.buildErrorMessage(
                err.response.data,
                'Die verfügbaren Geräte konnten nicht geladen werden!'
              )
            )
          )
          .finally(() => {
            this.devicesLoading = false;
          });
      }
    },
    addPresentDeviceToAvailableDevicesIfRequired() {
      if (
        this.presentDevice != null &&
        !this.availableDevices.includes(this.presentDevice)
      ) {
        // Add present device
        this.availableDevices.push(this.presentDevice);
      }
    },
    resetInput() {
      if (this.presentDevice !== null && this.presentDevice !== undefined) {
        this.deviceSearchInput = '';
        this.selectedDevice = this.presentDevice;
      } else {
        this.selectedDevice = null;
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
