<template>
  <div>
    <DataDisplay :label="label">
      <div
        v-if="
          addressDto &&
            (addressDto.email || addressDto.phone || addressDto.cellPhoneNumber)
        "
        class="display-body"
      >
        <div v-if="addressDto.email">
          Emailadresse:&nbsp;
        </div>
        <div v-if="addressDto.email">
          {{ addressDto.email }}
        </div>
        <div v-if="addressDto.phone">
          Telefonnummer:&nbsp;
        </div>
        <div v-if="addressDto.phone">
          {{ addressDto.phone }}
        </div>
        <div v-if="addressDto.cellPhoneNumber">
          Mobilnummer:&nbsp;
        </div>
        <div v-if="addressDto.cellPhoneNumber">
          {{ addressDto.cellPhoneNumber }}
        </div>
      </div>
    </DataDisplay>
  </div>
</template>

<script>
import DataDisplay from '@/components/elements/DataDisplay.vue';

export default {
  name: 'ContactInformationDisplay',
  components: { DataDisplay },
  props: {
    addressDto: {
      type: Object,
      required: false,
      default: null
    },
    label: {
      type: String,
      required: false,
      default: 'Kontaktdaten'
    }
  }
};
</script>

<style scoped lang="scss">
.display-body {
  display: grid;
  grid-template-columns: min-content auto;
}
</style>
