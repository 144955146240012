<template>
  <DataDisplay :label="label">
    <div v-if="date">
      {{ formatDate(date) }}
    </div>
  </DataDisplay>
</template>

<script>
import DataDisplay from '@/components/elements/DataDisplay.vue';
import TimeUtility from '@/util/TimeUtility.js';

export default {
  name: 'DateDisplay',
  components: { DataDisplay },
  props: {
    label: { type: String, required: true },
    date: {
      type: String,
      required: false,
      default: null
    }
  },
  methods: {
    formatDate(date) {
      return TimeUtility.formatLocalDate(date);
    }
  }
};
</script>

<style></style>
