<template>
  <div>
    <DataDisplay :label="label">
      <div
        v-if="
          addressDto &&
            (addressDto.zipCode ||
              addressDto.city ||
              addressDto.houseNumber ||
              addressDto.houseNumberSupplement)
        "
      >
        <div v-if="addressDto.street">
          <span>{{ addressDto.street }}&nbsp; </span>

          <span v-if="addressDto.houseNumberSupplement">
            {{ addressDto.houseNumber }}{{ addressDto.houseNumberSupplement }}
          </span>
          <span
            v-else-if="
              !addressDto.alternativeLocationDescriptionIsSelected &&
                addressDto.houseNumber
            "
          >
            {{ addressDto.houseNumber }}
          </span>
          <span
            v-else-if="
              addressDto.alternativeLocationDescriptionIsSelected &&
                addressDto.alternativeLocationDescription
            "
          >
            {{ addressDto.alternativeLocationDescription }}
          </span>
        </div>
        <div>{{ addressDto.zipCode }} {{ addressDto.city }}</div>
      </div>
    </DataDisplay>
  </div>
</template>

<script>
import DataDisplay from '@/components/elements/DataDisplay.vue';

export default {
  name: 'AddressDisplay',
  components: { DataDisplay },
  props: {
    addressDto: {
      type: Object,
      required: false,
      default: null
    },
    label: {
      type: String,
      required: false,
      default: 'Adresse'
    }
  }
};
</script>

<style></style>
