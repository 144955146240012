var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-autocomplete',{ref:"selectMsanChassis",staticClass:"margin-top-1",attrs:{"disabled":(_vm.availableMsanChassis.length ? false : true) ||
        _vm.disabled ||
        _vm.fetchingAutoSelection,"items":_vm.availableMsanChassis,"loading":_vm.msanChassisLoading,"append-outer-icon":"mdi-restore","rules":[
      function (selection) { return selection != null || 'Es muss ein MSAN-Chassis ausgewählt werden!'; }
    ],"dense":"","item-text":"searchLabel","item-value":"serviceDataId","label":"MSAN","persistent-hint":"","persistent-placeholder":"","return-object":""},on:{"change":function($event){return _vm.msanSelectionChanged($event.serviceDataId)},"click:append-outer":function($event){return _vm.resetMsanSelection()}},model:{value:(_vm.selectedMsanChassis),callback:function ($$v) {_vm.selectedMsanChassis=$$v},expression:"selectedMsanChassis"}}),_c('v-autocomplete',{ref:"selectPonPort",staticClass:"margin-top-1",attrs:{"append-outer-icon":"mdi-restore","disabled":(_vm.availablePonPorts.length ? false : true) ||
        _vm.disabled ||
        _vm.fetchingAutoSelection,"items":_vm.availablePonPorts,"loading":_vm.ponPortsLoading,"rules":[
      function (selection) { return selection != null || 'Es muss ein Pon-Port ausgewählt werden!'; }
    ],"dense":"","item-text":"searchLabel","item-value":"serviceDataId","label":"Pon-Port","persistent-hint":"","persistent-placeholder":"","return-object":""},on:{"change":function($event){return _vm.ponPortSelectionChanged($event.serviceDataId)},"click:append-outer":function($event){return _vm.resetPonPortSelection()}},model:{value:(_vm.selectedPonPort),callback:function ($$v) {_vm.selectedPonPort=$$v},expression:"selectedPonPort"}}),_c('v-autocomplete',{ref:"selectUniPort",staticClass:"margin-top-1",attrs:{"disabled":(_vm.availableUniPorts.length ? false : true) ||
        _vm.disabled ||
        _vm.fetchingAutoSelection,"items":_vm.availableUniPorts,"append-outer-icon":"mdi-restore","loading":_vm.uniPortsLoading,"rules":[
      function (selection) { return selection != null || 'Es muss ein Uni-Port ausgewählt werden!'; }
    ],"dense":"","item-text":"searchLabel","item-value":"serviceDataId","label":"Uni-Port","persistent-hint":"","persistent-placeholder":"","return-object":""},on:{"click:append-outer":function($event){return _vm.resetUniPortSelection()}},model:{value:(_vm.selectedUniPort),callback:function ($$v) {_vm.selectedUniPort=$$v},expression:"selectedUniPort"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }