var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DataDisplay',{attrs:{"label":"Registrierungsstatus"}},[(
        _vm.provisioningInformation &&
          (_vm.provisioningInformation.msanIsAlreadyProvisioned ||
            _vm.provisioningInformation.msanStatus === 'up')
      )?_c('div',{staticClass:"align-elements margin-bottom-1"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-web-check ")])]}}],null,false,3406274665)},[_c('span',[_vm._v(" MSAN-Status: "+_vm._s(_vm.provisioningInformation.msanStatus)+" ")])]),_vm._v("   Registriert ")],1):_c('div',{staticClass:"align-elements margin-bottom-1"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-web-off ")])]}}])},[_c('span',[_vm._v(" MSAN wurde noch nicht provisioniert ")]),(_vm.provisioningInformation)?_c('span',[_vm._v(" MSAN-Status: "+_vm._s(_vm.provisioningInformation.msanStatus)+" ")]):_vm._e()]),_vm._v("   Nicht registriert ")],1)]),_c('DataDisplay',{attrs:{"label":"Signallevel Empfang"}},[(
        _vm.provisioningInformation &&
          _vm.provisioningInformation.portReceiveSignalLevelDbm
      )?_c('div',{staticClass:"align-elements"},[_vm._v(" "+_vm._s(_vm.provisioningInformation.portReceiveSignalLevelDbm)+" dBm ")]):_vm._e()]),_c('DataDisplay',{attrs:{"label":"Entfernung zum OLT"}},[(
        _vm.provisioningInformation && _vm.provisioningInformation.portDistanceToOltKm
      )?_c('div',{staticClass:"align-elements"},[_vm._v(" "+_vm._s(_vm.provisioningInformation.portDistanceToOltKm)+" km ")]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }