var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hardwareDeviceSelectionGrid"},[_c('v-autocomplete',{ref:"selectedDevice",attrs:{"label":"Seriennummer","append-outer-icon":"mdi-restore","disabled":_vm.disabled,"items":_vm.availableDevices,"search-input":_vm.deviceSearchInput,"item-text":"serialNumber","item-value":"deviceId","no-data-text":"Geräte wurden noch nicht geladen.","hint":!_vm.deviceType && !_vm.disabled
        ? 'Für die Suche muss ein Produkt ausgewählt werden.'
        : null,"rules":[
      function (selection) { return !_vm.isRequired ||
        selection != null ||
        'Es muss eine Seriennummer ausgewählt werden!'; }
    ],"dense":"","persistent-hint":"","persistent-placeholder":"","return-object":""},on:{"update:search-input":function($event){{
        _vm.deviceSearchInput = $event != null ? $event : '';
        if (
          !_vm.selectedDevice ||
          _vm.deviceSearchInput !== _vm.selectedDevice.serialNumber
        ) {
          _vm.fetchSerialnumbers(_vm.deviceType, _vm.deviceSearchInput);
        }
      }},"click:append-outer":function($event){return _vm.resetInput()}},model:{value:(_vm.selectedDevice),callback:function ($$v) {_vm.selectedDevice=$$v},expression:"selectedDevice"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }